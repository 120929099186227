import { useMutation } from '@apollo/client';
import { IconsProvider } from '@lmc-eu/spirit-web-react';
import { Button } from '@lmc-eu/spirit-web-react/components/Button';
import classNames from 'classnames';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import React, { useContext } from 'react';
import ProfileCardInterestingJobDescriptionLink from '@components/Profile/ProfileCard/ProfileCardInterestingJobDescriptionLink';
import Graph from '@components/utilities/Graph/Graph';
import HtmlParser from '@components/utilities/HtmlParser';
import NextAlert from '@components/utilities/NextAlert/NextAlert';
import NextButtonLink from '@components/utilities/NextButtonLink/NextButtonLink';
import { useUser } from '@hooks/useUser';
import Tick from '@icons/tick.svg';
import { ImageMaxWidths } from '@libs/Enum/ImageMaxWidths';
import { ImpressionListEventType } from '@libs/Enum/ImpressionLists';
import pushGACompanyImpressionListEvent from '@libs/ga/pushGACompanyImpressionListEvent';
import pushGAInteractionEvent from '@libs/ga/pushGAInteractionEvent';
import { getProfileDetailUrl } from '@libs/getProfileDetailUrl';
import classes from './ProfileCard.module.scss';
import { ProfileImpressionListData } from '../../../generated/phpConstants';
import {
  AbstractProfile,
  LoggedInUserInfo,
  ProfileCardData,
  ProfileTabName,
  SubscribeToProfileNotificationMutation,
  SubscribeToProfileNotificationMutationVariables,
} from '../../../graphql/generated/types/graphqlTypes';
import { SUBSCRIBE_TO_PROFILE_NOTIFICATION } from '../../../graphql/mutations/subscribeToProfile';
import { UNSUBSCRIBE_CURRENTLY_LOGGED_IN_USER_FROM_JOB_DESCRIPTIONS_ON_PROFILE } from '../../../graphql/mutations/unsubscribeFromNewJobDescriptionsOnProfile';
import { addMaxResolutionToImageUrl } from '../../../helpers/imgSrcAttributesWithMaxResolution';
import { RequestContext } from '../../../helpers/requestContext';
import { loadingIcon } from '../../../public/loadingIcon';

export const sendCompanyImpressionListClickEvent = (
  profile: AbstractProfile,
  impressionListName: string,
  impressionListVariant: string,
  locale: string,
  position: number,
  url?: string,
) => {
  pushGACompanyImpressionListEvent(
    ImpressionListEventType.click,
    profile,
    impressionListName,
    impressionListVariant,
    locale,
    position,
    url,
  );
};

interface ProfileCardComponentProps extends React.ComponentPropsWithoutRef<'div'> {
  profileCardData: ProfileCardData;
  profileUrl: string;
  profileImpressionListName: string;
  profileImpressionListVariant: string;
}

interface ProfileCardCompanyCultureProps {
  profileCardData: ProfileCardData;
}

interface ProfileCardSubscribeButtonProps {
  profileCardData: ProfileCardData;
  onSubscribeButtonClick: () => void;
  isSubscribed: boolean;
  setIsSubscribed: React.Dispatch<React.SetStateAction<boolean>>;
  buttonColor: 'primary' | 'secondary';
}

export const ProfileCardPhoto = ({ profileCardData }: ProfileCardComponentProps) => {
  return (
    <div className={classes.ProfileCard__photoWrapper}>
      {profileCardData.isPhotoVisible && profileCardData.photo?.imageUrl && (
        <Image
          src={addMaxResolutionToImageUrl(profileCardData.photo?.imageUrl, ImageMaxWidths.PROFILE_CARD_PHOTO)}
          alt={profileCardData.profile.expandedName}
          fill
          className={classes.ProfileCard__photo}
        />
      )}
    </div>
  );
};

export const ProfileCardLogo = ({ profileCardData, className }: ProfileCardComponentProps) => {
  if (profileCardData.isLogoVisible && profileCardData.logo?.imageUrl) {
    return (
      <div className={classNames(classes.ProfileCard__logoWrapper, className)}>
        <img src={profileCardData.logo?.imageUrl} alt={profileCardData.profile.expandedName} />
      </div>
    );
  }

  return null;
};

export const ProfileCardReviewCount = ({ profileCardData }: ProfileCardComponentProps) => {
  const { t } = useTranslation();

  return (
    <span className="text-nowrap typography-body-small-text-regular text-secondary">
      {t('COUNT_OF_REVIEWS_PLAIN', {
        ns: 'profile+intl-icu',
        countOfReviews: profileCardData.countOfReviews,
      })}
    </span>
  );
};

export const ProfileCardReviewAndJobDescriptionCount = ({ profileCardData }: ProfileCardComponentProps) => {
  const { t } = useTranslation();

  return (
    <div className="typography-body-small-text-regular text-secondary">
      <span className="text-nowrap">
        {t('COUNT_OF_REVIEWS_PLAIN', {
          ns: 'profile+intl-icu',
          countOfReviews: profileCardData.countOfReviews,
        })}
      </span>
      {profileCardData.countOfJobDescriptions > 0 && (
        <>
          <span className="px-300"> • </span>
          <span className="text-nowrap">
            {t('COUNT_OF_JOB_OPPORTUNITIES_PLAIN', {
              ns: 'profile',
              countOfJobOpportunities: profileCardData.countOfJobDescriptions,
            })}
          </span>
        </>
      )}
    </div>
  );
};

interface ProfileCardJobOpportunitiesProps extends ProfileCardComponentProps {
  position: number;
}
export const ProfileCardJobOpportunities = ({
  profileCardData,
  profileImpressionListName,
  profileImpressionListVariant,
  position,
}: ProfileCardJobOpportunitiesProps) => {
  const { t, i18n } = useTranslation();
  const requestContextData = useContext(RequestContext);

  const absoluteBaseUrl = `https://${requestContextData?.hostname}`;
  const profileJobDescriptionsUrl = getProfileDetailUrl(
    i18n.language,
    profileCardData.profile,
    ProfileTabName.JobOpportunities,
  );

  if (profileCardData.countOfJobDescriptions && profileCardData.top3JobDescriptions?.length) {
    return (
      <div className={classes.ProfileCard__section}>
        <div className="typography-body-small-text-regular text-secondary">
          <div className="mb-500">{t('GO_TO_JOB_DESCRIPTIONS_LIST')}</div>
          <ul>
            {profileCardData.top3JobDescriptions.map(
              (jobDescriptionLinkWithProfileInfo, index) =>
                jobDescriptionLinkWithProfileInfo && (
                  <li
                    className={index < (profileCardData.top3JobDescriptions?.length || 0) - 1 ? 'mb-500' : 'mb-700'}
                    key={`interestingJobDescriptionLink_${index}`}
                  >
                    <ProfileCardInterestingJobDescriptionLink
                      href={jobDescriptionLinkWithProfileInfo.jobDescriptionLink.externalDetailUrl}
                      title={t(jobDescriptionLinkWithProfileInfo.jobDescriptionLink.externalDetailLinkLabel)}
                      target="_blank"
                      rel="noreferrer"
                      jobOpportunity={jobDescriptionLinkWithProfileInfo.jobDescriptionLink}
                      impressionListName="premium jobs list interesting companies"
                      impressionListCompanyVariant={jobDescriptionLinkWithProfileInfo.impressionListCompanyVariant}
                      position={index + 1}
                    >
                      {jobDescriptionLinkWithProfileInfo.jobDescriptionLink.title}
                    </ProfileCardInterestingJobDescriptionLink>
                  </li>
                ),
            )}
          </ul>
          <NextButtonLink
            href={`${profileJobDescriptionsUrl}#main`}
            isBlock
            color="secondary"
            onClick={() =>
              sendCompanyImpressionListClickEvent(
                profileCardData.profile,
                profileImpressionListName,
                profileImpressionListVariant,
                i18n.language,
                position,
                `${absoluteBaseUrl}${profileJobDescriptionsUrl}#main`,
              )
            }
          >
            {t('GO_TO_JOB_OPPORTUNITIES_ICU', {
              ns: 'profile+intl-icu',
              count: profileCardData.countOfJobDescriptions,
            })}
          </NextButtonLink>
        </div>
      </div>
    );
  }

  if (profileCardData.profile.externalProfileUrl) {
    return (
      <div className={classes.ProfileCard__section}>
        <h4 className="typography-heading-xsmall-text">{t('CHECK_COMPANY_JOB_OPPORTUNITIES_TITLE')}</h4>
        <p className="typography-body-small-text-regular">{t('CHECK_COMPANY_JOB_OPPORTUNITIES_PARAGRAPH')}</p>
        <NextButtonLink href={profileCardData.profile.externalProfileUrl as string} isBlock color="secondary">
          {t('SHOW_JOB_OPPORTUNITIES_ON_COMPANY_WEBSITE')}
        </NextButtonLink>
      </div>
    );
  }

  return null;
};

export const ProfileCardSuitableFor = ({ profileCardData }: ProfileCardComponentProps) => {
  const { t } = useTranslation();

  if (profileCardData.top3SuitableForLabels.length > 0) {
    return (
      <>
        <div className="mb-500 typography-body-small-text-regular text-secondary">
          {t('BEST_THINGS_HEADER', { ns: 'profile' })}
        </div>
        <ul>
          {profileCardData.top3SuitableForLabels.map((suitableForLabel, index) => (
            <li className="mb-500 typography-body-small-text-regular" key={`top3SuitableFor_${index}`}>
              {t(suitableForLabel, { ns: 'profile' })}
            </li>
          ))}
        </ul>
      </>
    );
  }

  return null;
};

export const ProfileCardCompanyCulture = ({ profileCardData }: ProfileCardCompanyCultureProps) => {
  const { t } = useTranslation();

  if (
    profileCardData.employerSatisfaction &&
    profileCardData.colleaguesSatisfaction &&
    profileCardData.bossSatisfaction
  ) {
    return (
      <>
        <div className="typography-body-small-text-regular text-secondary">
          {t('COMPANY_CULTURE_HEADER', { ns: 'profile' })}:
        </div>
        <Graph
          label={t('SATISFACTION_WITH_EMPLOYER', { ns: 'profile' })}
          percentage={profileCardData.employerSatisfaction}
        />
        <Graph
          label={t('SATISFACTION_WITH_COLLEAGUES', { ns: 'profile' })}
          percentage={profileCardData.colleaguesSatisfaction}
        />
        <Graph label={t('SATISFACTION_WITH_BOSS', { ns: 'profile' })} percentage={profileCardData.bossSatisfaction} />
      </>
    );
  }

  return null;
};

export const ProfileCardSubscribeButton = ({
  onSubscribeButtonClick,
  profileCardData,
  isSubscribed,
  setIsSubscribed,
  buttonColor,
}: ProfileCardSubscribeButtonProps) => {
  const { t } = useTranslation(['profile', 'messages']);
  const user = useUser();

  const [
    unsubscribeCurrentlyLoggedInUserFromJobDescriptionsOnProfile,
    { error: unsubscribeError, loading: unsubscribeLoading },
  ] = useMutation(UNSUBSCRIBE_CURRENTLY_LOGGED_IN_USER_FROM_JOB_DESCRIPTIONS_ON_PROFILE);

  const [subscribeToProfile, { error: subscribeError, loading: subscribeLoading }] = useMutation<
    SubscribeToProfileNotificationMutation,
    SubscribeToProfileNotificationMutationVariables
  >(SUBSCRIBE_TO_PROFILE_NOTIFICATION);

  const loading = subscribeLoading || unsubscribeLoading;

  const handleJobDescriptionSubscriptionForLoggedInUser = (loggedInUser: LoggedInUserInfo) => {
    if (isSubscribed) {
      unsubscribeCurrentlyLoggedInUserFromJobDescriptionsOnProfile({
        variables: {
          profileUrlIdentifier: profileCardData.profile.urlIdentifier,
        },
      }).then((result) => {
        if (result?.data.unsubscribeCurrentlyLoggedInUserFromJobDescriptionsOnProfile) {
          setIsSubscribed(false);
        }
      });
    } else
      subscribeToProfile({
        variables: {
          profileIds: [profileCardData.profile.id],
          email: loggedInUser.email,
          subscribeToNewReviews: false,
          subscribeToNewJobDescriptions: true,
        },
      }).then((result) => {
        if (result?.data?.subscribeToProfileNotification.success) {
          pushGAInteractionEvent(
            'ga.event',
            'subscribeToCompanyProfileNotifications',
            'subscribeSuccessful jobs',
            profileCardData.profile.expandedName,
            0,
            {
              hit: {
                company_id: profileCardData.profile.urlIdentifier,
                company_name: profileCardData.profile.expandedName,
                company_variant: profileCardData.latestActiveContract
                  ? ProfileImpressionListData.GTM_ATM_PAID_VARIANT
                  : ProfileImpressionListData.GTM_ATM_UNPAID_VARIANT,
              },
            },
          );

          setIsSubscribed(true);
        }
      });
  };

  const handleOnSubscribeClicked = () => {
    if (user) {
      handleJobDescriptionSubscriptionForLoggedInUser(user);
    }
    onSubscribeButtonClick();
  };

  return (
    <IconsProvider value={loadingIcon}>
      <Button size="large" color={buttonColor} onClick={handleOnSubscribeClicked} isLoading={loading}>
        {isSubscribed ? (
          <>
            <div className="mr-400">
              <Tick />
            </div>
            {user ? t('ALREADY_SUBSCRIBED_TO_NEW_JOB_DESCRIPTIONS_BUTTON') : t('SELECTED_JOB_DESCRIPTION_BUTTON')}
          </>
        ) : (
          <>{t('SUBSCRIBE_TO_NEW_JOB_DESCRIPTIONS_BUTTON')}</>
        )}
      </Button>
      {(subscribeError || unsubscribeError) && (
        <NextAlert className="mt-400" color={'danger'}>
          <HtmlParser htmlString={t('PROFILE_CARD_SUBSCRIPTION_FAILED')} />
        </NextAlert>
      )}
    </IconsProvider>
  );
};
